import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PropertyWorthState {
	private readonly _isProcessing$ = new BehaviorSubject(false);

	get isProcessing$() {
		return this._isProcessing$.asObservable();
	}

	setProcessing(state: boolean): void {
		this._isProcessing$.next(state);
	}
}
